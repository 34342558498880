<template>
  <div id="bs-content">

    <!-- <article class="bs-article">
      <div class="row">
        <div class="col-md-6 order-2 order-md-1">
            <label>The 1000Blocks Platform1</label>
            <h1>Truly unique:<br /> Co-creating NFTs, having fun and earning yields</h1>
            <p>The 1000Blocks platform leverages Blockchain technology, running on the Binance Smart Chain (BSC). 
              It utilizes BNB coins and our native BLS token as its primary crypto assets.</p>

            <p>On the 1000Blocks Dapp users can enter a Space and claim blocks.
              What happens when a block is claimed depends on the rules of the Space. Each Space is unique!
              The only thing in common to all Spaces: 
              1. At some point a snapshot is done and a NFT is minted. 
              2. Every time a block is claimed, Universal Rewards Pool’s value goes up!</p> 
            <p>All the key pieces of the platform are explained bellow:</p>
        </div>
        <div class="col-md-6 order-1 order-md-2">
            <img src="../assets/img/1000BS-how-it-works.png" alt="HOW IT WORKS" class="bs-res-img" />
        </div>
      </div>
      <div class="row t-hide">
        <div class="col-2">
          <img src="../assets/img/element_user.png" alt="The App" class="bs-res-img" />
          <label class="icon-label">The User</label>
        </div>
        <div class="col-2">
          <img src="../assets/img/element_app.png" alt="The App" class="bs-res-img" />
          <label class="icon-label">The Dapp</label>
        </div>
        <div class="col-2">
          <img src="../assets/img/element_spaces.png" alt="The App" class="bs-res-img" />
          <label class="icon-label">Spaces</label>
        </div>
        <div class="col-2">
          <img src="../assets/img/element_pool.png" alt="The App" class="bs-res-img" />
          <label class="icon-label">Rewards Pool</label>
        </div>
        <div class="col-2">
          <img src="../assets/img/element_vault.png" alt="The App" class="bs-res-img" />
          <label class="icon-label">Vault</label>
        </div>
        <div class="col-2">
          <img src="../assets/img/element_token.png" alt="The App" class="bs-res-img" />
          <label class="icon-label">BLS Token</label>
        </div>
      </div>
    </article> -->
              
    <!-- <article class="row bs-article">
        <div class="col-md-6">
            <img src="../assets/img/how-to_metamask.png" alt="The App" class="bs-res-img" />
        </div>
        <div class="col-md-6">
            <h2>1. The User</h2>
            <p>We consider anyone who access the 1000Blocks Dapp and connects a Wallet to be a User.
                Connect your wallet via MetaMask to use the Dapp on both desktop and mobile.
                For both, desktop and mobile. Other providers such as TrustWallet are still being tested.
            </p>
            <p>Once connected to the Dapp, Users can:
              <ul>
                <li>participate in the co-creation of an NFT</li>
                <li>stake BLS tokens</li>
                <li>yield rewards from Rewards Pool</li>
              </ul>
               As we continue to innovate, Users will have more opportunities to express their creativity, 
               engage with the community and yield rewards.
            </p>
        </div>
    </article> -->

    <article class="row bs-article">
      <section class="row">
        <div class="col-md-8 order-2 order-md-1">
            <h2>1. The Dapp</h2>
            <p>The 1000Blocks Dapp (currently optimized for desktop) is the primary way to interact with the platform.
              The Dapp gives the end-user a graphical user interface to be able to co-create an NFT in a simple and fun way.                      
              Through the 1000Blocks Dapp the user will have access to various Spaces.
              </p>
            <a href="https://app.1000blocks.space" target="_blank" class="bs-button-secondary">Launch App</a>
        </div>
        <div class="col-md-4 order-1 order-md-2">
            <img src="../assets/img/how-to_dapp.png" alt="The App" class="bs-res-img" />
        </div>
      </section>
      <!-- <section class="row">
        <div class="col-12">
          <h3>Global Header</h3>
          <p>The Global Header provides quick access to key features and is the starting point to connect to the Dapp.</p>
          <img src="../assets/img/exmpl-ui_space-header.png" alt="The App" class="bs-res-img" />
        </div>
      </section> -->
    </article>

    <article class="row bs-article">
      <section class="row">
        <div class="col-lg-6">
            <img src="../assets/img/how-to_spaces.png" alt="The App" class="bs-res-img" />
        </div>
        <div class="col-lg-6">
            <h2>2. SPACES</h2>
            <p>The centerpiece of the 1000Blocks Dapp. A Space is a virtual place for users to co-create a NFT and earn yield in the process.
              Very important to know: every Space has its own rules. These rules dictate:
              <ul>
                <li>How many Blocks a user can claim?</li>
                <li>On what interval?</li>
                <li>What happens when a Block is claimed?</li>
                <li>How big is the yield?</li>
                <li>When the NFT will be minted?</li>
                <li>Etc...</li>
              </ul>     
              The rules of each Space can be found in the Space itself.
              </p>
        </div>
      </section>
      <section class="row">
        <div class="col-md-4 mb-4">
          <h4>Space Selector</h4>
          <img src="../assets/img/exmpl-ui_space-selector.png" alt="The App" class="bs-res-img" />
          <p>The Space Selector is the first thing the User sees when opening the Dapp. 
             It provides an overview of all Spaces the User can join.
          </p>
        </div>
        <div class="col-md-4 mb-4">
          <h4>Space Canvas</h4>
          <img src="../assets/img/exmpl-ui_space-canvas.png" alt="The App" class="bs-res-img" />
          <p>The Space Canvas is the center piece of the Dapp. A grid, that consists of 1008 blocks (42*24). 
            8 blocks are by default covered with the platform logo. 1000 are left for the user to interact with.
          </p>
        </div>
        <div class="col-md-4 mb-4">
          <h4>Space Navigator</h4>
          <img src="../assets/img/exmpl-ui_space-navigator.png" alt="The App" class="bs-res-img" />
          <p> Your best friend when exploring a Space, 
            the Space Navigator shows all information and available actions, 
            such as claiming blocks or harvesting rewards.
          </p>
        </div>
      </section>
      <section class="row">
        <div class="col-md-6 order-1 order-md-2">
            <h3>BLOCKS</h3>
            <p>The main elements of every Space. Each space has 1008 of them (42x24). 
              8 have an Owner from the start. 
              1000 are up for grabs when the Space opens.
            </p>
        </div>
        <div class="col-md-6 order-2 order-md-1">
            <img src="../assets/img/how-to_blocks.png" alt="The App" class="bs-res-img" />
        </div>
      </section>
      <section class="row">
        <div class="col-md-4">
          <h4>Claiming Blocks</h4>
          <img src="../assets/img/exmpl-ui_claim-blocks.png" alt="The App" class="bs-res-img" />
          <p>Blocks can be Claimed. How many Blocks one can claim and how often depends on the rules of the Space. 
              Same goes for the Claim Fee (CF).</p>
        </div>
        <div class="col-md-4">
          <h4>Block Take Over</h4>
          <img src="../assets/img/exmpl-ui_takeover-blocks.png" alt="The App" class="bs-res-img" />
          <p>The rules of each space will dictate if, 
            when and for how much (the Take Over Fee) a block can be taken over.</p>
        </div>
        <div class="col-md-4">
          <h4>Cool points</h4>
          <img src="../assets/img/exmpl-ui_visible-block.png" alt="The App" class="bs-res-img" />
          <p>As long as one is the Owner of a block (content is visible) one earns points. 
            Each space owner then decides how participants will be rewarded. Randomly, top 10, bottom 10 etc.
          </p>
        </div>
      </section>
    </article>

    <!-- <article class="row bs-article">
        <div class="col-md-6 order-2 order-md-1">
            <h2>4. Rewards Pool</h2>
            <p>Different spaces have different rules. 
              But one is common to all: the majority of the proceeds collected through the creation process and the final auction of the NFT go to the Rewards Pool.
            </p>
            <p>There is one global Rewards Pool. 
              The sum in the Pool gets linearly distributed over the course of 21 days (from the time of the last transaction to the Pool) to all users who have tokens staked in the Vault. 
              Of course, relative to the percentage of their stake in the Vault.
            </p>
            <p>
              It can be, that in the future some Spaces will also have a local Rewards Pool only accessible to participants in that particular space. 
              But for now, we have one "to rule them all"
            </p>
        </div>
        <div class="col-md-6 order-1 order-md-2">
            <img src="../assets/img/how-to_rewardspool.png" alt="The App" class="bs-res-img" />
        </div>
    </article>

    <article class="row bs-article">
        <div class="col-md-6 order-1 order-md-2">
            <h2>5. The Vault</h2>
            <p>Where the magic happens. 
              A user can stake BLS tokens into our Block Space Vault.
              Here’s a quick working example of the linear distribution of rewards, outlined in section 4, for staking BLS in The Vault:
            </p>
            <p>
              <ul>
                <li>You have staked 10.000 BLS</li>
                <li>In the Vault, there is a total of 1.000.000BLS</li>
                <li>Your relative stake is 1%</li>
                <li>The Rewards Pool is 100BNB</li>
                <li>If nothing changes you will over the course of 21 days receive 1BNB (0.0476 per day)</li>
              </ul>
            </p>
            <p>Important to note: when a user withdraws from the Vault, 1% of his tokens get burned. Currently one can only Withdraw the full staked amount.</p>
        </div>
        <div class="col-md-6 order-2 order-md-1">
            <img src="../assets/img/how-to_vault.png" alt="The Vault" class="bs-res-img" />
        </div>
    </article>

    <article class="row bs-article">
        <div class="col-md-6 order-2 order-md-1">
            <h2>6. BLS Token</h2>
            <p>Our 1000Blocks.Space platform has its native token called the BLS Token. 
              The token can be used for multiple purposes such as participating in a Space, earning rewards and staking. More utility will be added over time. To find more about the BLS token please visit the token page.
            </p>
            <router-link to="/bls-token" class="bs-button-secondary">All about the BLS Token</router-link>
        </div>
        <div class="col-md-6 order-1 order-md-2">
            <img src="../assets/img/how-to_token.png" alt="BLS Token" class="bs-res-img" />
        </div>
    </article> -->


  </div>
</template>

<script>
export default {
  name: 'How it works',
  components: {

  }
}
</script>

<style scoped>
  .bs-article section {
    margin-bottom: 64px;
  }
</style>
