<template>
  <div id="bs-page-selector">
      <router-link class="m-nav" to="/">Home</router-link>
      <router-link class="m-nav" to="/how-it-works">How it works</router-link>
      <!-- <router-link class="m-nav" to="/bls-token">BLS Token</router-link> -->
      <!-- <a href="https://spaceapes.1000blocks.space" class="m-nav">SpaceApes NFTs</a> -->
      <!-- <router-link class="m-nav" to="/roadmap">Roadmap</router-link> -->
      <!-- <router-link class="m-nav" to="/contact">Get in touch</router-link> -->
      <router-link class="m-nav" to="/team">Project team</router-link>
      <!-- <router-link class="m-nav" to="/jobs">Job board</router-link> -->
      <!-- <router-link class="m-nav" to="/transparency">Transparency report</router-link> -->
      <!-- <router-link class="m-nav" to="/branding">Branding</router-link> -->
      <a href="https://app.1000blocks.space" class="bs-cta bs-b-block" style="font-size: 1.5em; padding: 32px">LAUNCH DAPP - SOON</a>
  </div>
</template>

<script>
export default {
  name: 'PageSelector',
  components: {

  }
}
</script>
<style scoped>
  #bs-page-selector {
    margin: 52px -16px 0 -16px;
  }

  #bs-page-selector .m-nav {
    text-align: center;
    display: block;
    padding: 32px;
    background-color: #111;
    border-top: #333 solid 1px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.5em;
  }
</style>